/* .App {
  text-align: center;
} */

@import url(http://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);
html, body, html * {
  font-family: 'Roboto', sans-serif;
}

.hubspot-form {
  height: 100vh;
}
@media only screen and (min-width: 701px) and ( max-width: 1600px) {
.contain {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: auto;
  scroll-snap-type: y mandatory;
}

.contain .section {
  position: relative;
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
  background-blend-mode: multiply;
}

.contain .section:nth-child(1) {
  background-color: #F4D134;
  background-size: cover;

}

.contain .section:nth-child(2) {
  background-color: #2B2E51;
  background-size: cover;

}
.contain .section:nth-child(3) {
  background-color: #F4D134;
  background-size: cover;
}
.contain .section:nth-child(4) {
  background-color: #2B2E51;
  background-size: auto;

}
.contain .section:nth-child(5) {
  background-color: #F4D134;
  background-size: auto;

}
.contain .section:nth-child(6) {
  background-color: #2B2E51;
  background-size: auto;
}

canvas {
  position : absolute; 
  width:100%;
  height:100%;
  z-index: 1;
}

.logo img{
  margin-top: 1vh;
  width: 20%;
  height: 100%;
  padding: 0.4vw;
}

span {
  color: red;
}

.contain .section .hitched {
  position: absolute;
  left: 0;
  top: 50%;
  margin-left: 1vh;
}

.changing-names {
  font-weight: 700;
  font-size: 2vw;
}

.tagline {
  opacity: 0.6;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button-text {
  font-size: small;
  padding: 2px;
  color: black;
}

#btn {
  border-color: black;
  border: 3px 3px;
  background-color: #F4D134;
  z-index: 1;
  border-radius: 30px;
}

.svg, #lang-svg {
  height: 100%;
}

.container-chess, .container-langue, .container-yoga {
  background-color: #2B2E51;
}
.container-string, .container-pirate {
  background-color: #F4D134;
}

.chess-content-button button, .yoga-content-button button, .langue-content-button button{
  position: absolute;
  top: 80%;
  left: 5%;
  background-color: #2B2E51;
  border: 3px 3px;
  border-color: #F4D134;
}

.string-content-button button, .pirate-content-button button{
  position: absolute;
  top: 80%;
  left: 5%;
  background-color: #F4D134;
  border: 3px 3px;
  border-color: #2B2E51;
}

.chess-content-button-text, .yoga-content-button-text, .langue-content-button-text {
  letter-spacing: 2px;
  font-size: large;
  color: #F4D134;
  margin-left: 1vh;
  margin-right: 1vh;
}

.string-content-button-text, .pirate-content-button-text {
  letter-spacing: 2px;
  font-size: large;
  color: #2B2E51;
  margin-left: 1vh;
  margin-right: 1vh;
}

.chess-content-para, .yoga-content-para, .langue-content-para {
  width: 50%;
  position: absolute;
  top: 20%;
  left: 5%;
  color: #F4D134;
  font-size: 2.5vh;

}

.chess-content-points, .yoga-content-points, .langue-content-points {
  width: 50%;
  position: absolute;
  top: 60%;
  left: 5%;
  font-size: 3vh;
  color: #F4D134
}
.pirate-content-para, .string-content-para{
  width: 50%;
  position: absolute;
  top: 20%;
  left: 5%;
  color: #2B2E51;
  font-size: 2.5vh;

}

.pirate-content-points, .string-content-points{
  width: 50%;
  position: absolute;
  top: 60%;
  left: 5%;
  font-size: 3vh;
  color: #2B2E51
}
.pirate-content-head, .string-content-head{
  width: 50%;
  position: absolute;
  top: 10%;
  left: 5%;
  font-size: 3vh;
  color: #2B2E51
}

.chess-content-head, .yoga-content-head, .langue-content-head {
  width: 50%;
  position: absolute;
  top: 10%;
  left: 5%;
  font-size: 3vh;
  color: #F4D134
}


  #knight, #pirates{
  height: 100%;
  width: 50%;
  float: right;
}

#lang {
  height: 90%;
  float: right;
  width: 50%;
  margin-top: 5vh;
  margin-right: 5vh;
}

#string {
  margin-right: 1vh;
  height: 100%;
  float: right;
  width: 60%;
  margin-top: -7vh;
}

#yoga {
  float: right;
  height: 100%;
  width: 40%;
  margin-top: 9vh;
}
  #knight2, #yoga2, #string2, #langue2 {
  height: 100%;
  width: 20%;
  float: right;
}

#yoga2 {
  height: 95%;
  margin-top: 3vh;
}

#pirate2 {
  margin-bottom: 2vh;
  height: 95%;
  float: right;
  width: 30%;
}
.logo{
  background-color: #F4D134;
}

.svg2 {
  height: 92.5vh;
}
#btn-yoga:hover {
  border: 2px solid red;
}
#btn-chess:hover {
  border: 2px solid red;
}
#btn-pirates:hover {
  border: 2px solid red;
}
#btn-lang:hover {
  border: 2px solid red;
}
#btn-string:hover {
  border: 2px solid red;
}

.heading-chess {
  font-size: 7vh;
  position: absolute;
  top: 0;
  left: 5%;
  font-weight: 800;
  color: #F4D134;
  letter-spacing: -1px;
  margin-top: 3vh;
}

.heading-pirates {
  font-size: 7vh;
  position: absolute;
  top: 0;
  left: 5%;
  font-weight: 800;
  color: #2B2E51;
  letter-spacing: 1px;
  margin-top: 3vh;
}

.heading-yoga {
  font-size: 7vh;
  position: absolute;
  top: 0;
  left: 5%;
  font-weight: 800;
  color: #F4D134;
  letter-spacing: 0px;
  margin-top: 3vh;
}

.heading-string {
  font-size: 7vh;
  position: absolute;
  top: 0;
  left: 5%;
  font-weight: 800;
  color: #2B2E51;
  letter-spacing: 0px;
  margin-top: 3vh;
}

.heading-lang {
  font-size: 7vh;
  font-weight: 800;
  position: absolute;
  top: 0;
  left: 5%;
  color: #F4D134;
  letter-spacing: 1px;
  margin-top: 3vh;
}

.subHeading-chess {
  font-size: 3vh;
  font-weight: 600;
  position: absolute;
  top: 20%;
  left: 5%;
  padding: 4px;
  color: #F4D134;
  letter-spacing: 3px;
  margin-top: 1vh;
}

.subHeading-pirates {
  font-size: 3vh;
  font-weight: 600;
  padding: 4px;
  position: absolute;
  top: 20%;
  left: 5%;
  color: #2B2E51;
  letter-spacing: 3px;
  margin-top: 1vh;
}

.subHeading-yoga {
  font-size: 3vh;
  font-weight: 600;
  padding: 4px;
  position: absolute;
  top: 20%;
  left: 5%;
  color: #F4D134;
  letter-spacing: 3px;
  margin-top: 1vh;
}

.subHeading-string {
  font-size: 3vh;
  font-weight: 600;
  padding: 4px;
  position: absolute;
  top: 20%;
  left: 5%;
  color: #2B2E51;
  letter-spacing: 5px;
  margin-top: 1vh;
  margin-left: 1vh;
}

.subHeading-lang {
  font-size: 3vh;
  font-weight: 600;
  position: absolute;
  top: 20%;
  left: 5%;
  padding: 4px;
  color: #F4D134;
  letter-spacing: 3px;
  margin-top: 1vh;
}

.text-chess {
  color: #F4D134;
  margin-top: 3vh;
  position: absolute;
  top: 40%;
  left: 5%;
  letter-spacing: 1px;
}

.text-pirates {
  color: #2B2E51;
  margin-top: 3vh;
  position: absolute;
  top: 40%;
  left: 5%;
}

.text-yoga {
  color: #F4D134;
  margin-top: 3vh;
  letter-spacing: 1px;
  font-size: 3vh;
  position: absolute;
  top: 40%;
  left: 5%;
  font-weight: 200;
}
.text-lang {
  color: #F4D134;
  margin-top: 2vh;
  letter-spacing: 0px;
  font-size: 3vh;
  position: absolute;
  top: 40%;
  left: 5%;
}
#btn-chess {
  background-color: #2B2E51;
  border: 3px solid #F4D134;
  position: absolute;
  top: 60%;
  left: 5%;
}

#btn-pirates {
  background-color: #F4D134;
  border: 3px solid #2B2E51;
  position: absolute;
  top: 60%;
  left: 5%;
  margin-bottom: 2vh;
}

#btn-yoga {
  background-color: #2B2E51;
  border: 3px solid #F4D134;
  position: absolute;
  top: 60%;
  left: 5%;
  margin-bottom: 2vh;
}


#btn-string {
  background-color: #F4D134;
  border: 3px solid #2B2E51;
  position: absolute;
  top: 60%;
  left: 5%;
  margin-bottom: 1vh;
}

#btn-lang {
  position: absolute;
  top: 60%;
  left: 5%;
  background-color: #2B2E51;
  border: 3px solid #F4D134;
}

.button-text-chess {
  letter-spacing: 2px;
  font-size: large;
  color: #F4D134;
  margin-left: 1vh;
  margin-right: 1vh;
}

.button-text-pirates {
  letter-spacing: 2px;
  font-size: large;
  color: #2B2E51;
  margin-left: 1vh;
  margin-right: 1vh;
}

.button-text-yoga {
  letter-spacing: 2px;
  font-size: large;
  color: #F4D134;
  margin-left: 1vh;
  margin-right: 1vh;
}

.button-text-string {
  letter-spacing: 2px;
  font-size: large;
  color: #2B2E51;
  margin-left: 1vh;
  margin-right: 1vh;
}

.button-text-lang {
  letter-spacing: 2px;
  font-size: large;
  color: #F4D134;
  margin-left: 1vh;
  margin-right: 1vh;
}

}

/* 
************start for mobile********************* */




@media only screen and (max-width: 700px) {

  html, body{
    margin: 0;
    padding: 0;
  }
  * {
    padding: 0;
    margin: 0;
    text-align: justify;

  }
  .contain {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: auto;
    scroll-snap-type: y mandatory;
  }
  
  .contain .section {
    position: relative;
    width: 100%;
    height: 100%;
    scroll-snap-align: start;
    background-blend-mode: normal;
  }
  
  .contain .section:nth-child(1) {
    background-color: #F4D134;
    background-size: auto;  
    position: relative;
  }

  canvas {
    position : absolute; 
    width:100%;
    height:100%;
  }
  
  .contain .section:nth-child(2) {
    background-color: #2B2E51;
    background-size: auto;
  
  }
  .contain .section:nth-child(3) {
    background-color: #F4D134;
    background-size: auto;
  
  }
  .contain .section:nth-child(4) {
    background-color: #2B2E51;
    background-size: auto;
  
  }
  .contain .section:nth-child(5) {
    background-color: #F4D134;
    background-size: auto;
  
  }
  .contain .section:nth-child(6) {
    background-color: #2B2E51;
    background-size: auto;
  }
  
  .logo img{
    margin-top: 1vh;
  }
  span {
    color: red;
  }
  
  .changing-names {
    font-weight: 700;
    font-size: 14vw;
    margin-top: 6vh;
    text-align: center;
  }
  
  .tagline {
    opacity: 0.6;
    margin-top: 5vh;
    margin-bottom: 1vh;
    font-size: 7vw;
    text-align: center;
  }
  
  .button-text {
    font-size: 5vw;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 5px;
    margin-right: 5px;
    color: black;
  }

  #btn {
    border: 3px solid black;
    background-color: #F4D134;
    z-index: 1;
    border-radius: 30px;
    position: absolute;
    left: 14%;
    bottom: 15%;
  }

  .svg {
    height: 55vh;
  }

  .svg2 {
    height: 90vh;
  }
  
  .chess-content-button-text {
    letter-spacing: 2px;
    font-size: large;
    color: #F4D134;
    margin-left: 1vh;
    margin-right: 1vh;
  }
  
  .chess-content-para {
    width: 50%;
    position: absolute;
    top: 20%;
    left: 5%;
    color: #F4D134;
    font-size: 2.2vh;
  
  }
  
  .chess-content-points {
    width: 50%;
    position: absolute;
    top: 80%;
    left: 5%;
    font-size: 2.5vh;
    color: #F4D134
  }

  .chess-content-head {
    width: 50%;
    position: absolute;
    top: 10%;
    left: 5%;
    font-size: 2.8vh;
    color: #F4D134
  }

  .chess-content-button button{
    position: absolute;
    top: 93%;
    left: 10%;
    background-color: #2B2E51;
    border: 3px 3px;
    border-color: #F4D134;
  }

  .langue-content-button-text {
    letter-spacing: 2px;
    font-size: large;
    color: #F4D134;
    margin-left: 1vh;
    margin-right: 1vh;
  }
  
  .langue-content-para {
    width: 50%;
    position: absolute;
    top: 20%;
    left: 5%;
    color: #F4D134;
    font-size: 2vh;
  
  }
  
  .langue-content-points {
    width: 50%;
    position: absolute;
    top: 71%;
    left: 5%;
    font-size: 2.5vh;
    color: #F4D134
  }

  .langue-content-head {
    width: 50%;
    position: absolute;
    top: 10%;
    left: 5%;
    font-size: 2.8vh;
    color: #F4D134
  }

  .langue-content-button button{
    position: absolute;
    top: 93%;
    left: 10%;
    background-color: #2B2E51;
    border: 3px 3px;
    border-color: #F4D134;
  }

  .yoga-content-button-text {
    letter-spacing: 2px;
    font-size: large;
    color: #F4D134;
    margin-left: 1vh;
    margin-right: 1vh;
  }
  
  .yoga-content-para {
    width: 50%;
    position: absolute;
    top: 22%;
    left: 5%;
    color: #F4D134;
    font-size: 2vh;
  
  }
  
  .yoga-content-points {
    width: 50%;
    position: absolute;
    top: 77.5%;
    left: 5%;
    font-size: 2.5vh;
    color: #F4D134
  }

  .yoga-content-head {
    width: 50%;
    position: absolute;
    top: 9%;
    left: 5%;
    font-size: 2.8vh;
    color: #F4D134
  }

  .yoga-content-button button{
    position: absolute;
    top: 93%;
    left: 10%;
    background-color: #2B2E51;
    border: 3px 3px;
    border-color: #F4D134;
  }
  .pirate-content-para {
    width: 50%;
    position: absolute;
    top: 15%;
    left: 5%;
    color: #2B2E51;
    font-size: 2.2vh;
  
  }

  .pirate-content-button-text {
    letter-spacing: 2px;
    font-size: large;
    color: #2B2E51;
    margin-left: 1vh;
    margin-right: 1vh;
  }

  .pirate-content-head {
    width: 50%;
    position: absolute;
    top: 10%;
    left: 5%;
    font-size: 2.8vh;
    color: #2B2E51
  }
  
  .pirate-content-points {
    width: 50%;
    position: absolute;
    top: 75%;
    left: 5%;
    font-size: 2.5vh;
    color: #2B2E51
  }

  .pirate-content-button button{
    position: absolute;
    top: 90%;
    left: 10%;
    background-color: #F4D134;
    border: 3px 3px;
    border-color: #2B2E51;
  }
  .string-content-para {
    width: 50%;
    position: absolute;
    top: 20%;
    left: 5%;
    color: #2B2E51;
    font-size: 2.2vh;
  
  }

  .string-content-button-text {
    letter-spacing: 2px;
    font-size: large;
    color: #2B2E51;
    margin-left: 1vh;
    margin-right: 1vh;
  }

  .string-content-head {
    width: 50%;
    position: absolute;
    top: 10%;
    left: 5%;
    font-size: 2.8vh;
    color: #2B2E51
  }
  
  .string-content-points {
    width: 50%;
    position: absolute;
    top: 72%;
    left: 5%;
    font-size: 2.5vh;
    color: #2B2E51
  }

  .string-content-button button{
    position: absolute;
    top: 93%;
    left: 10%;
    background-color: #F4D134;
    border: 3px 3px;
    border-color: #2B2E51;
  }

     #pirates, #yoga, #string{
      height: 60vh;
      width: 100%;
      margin-top: 2vh;
  }

  #yoga {
    height: 70vh;
  }

  .logo img{
    width: 60%;
    height: 100%;
    margin-bottom: 1vh;
    margin-left: 0.5vh;

  }

  #lang {
    height: 55vh;
    width: 100%;
      margin-top: 2vh;
  }

 .svg-lang {
   margin-left: 2vh;
   margin-right: 2vh;
   height: 50vh;
 }

  #knight {
    height: 100%;
    width: 110%;
    margin-left: -2vh;
  }

  #knight2, #pirate2, #yoga2, #string2, #langue2 {
    height: 100%;
    width: 35%;
    float: right;
  }

  .logo{
    background-color: #F4D134;
  }

  #logo {
    height: 50%;
    width: 100%;
  }
  .container-chess, .container-langue, .container-yoga {
    background-color: #2B2E51;
    height: 100vh;
    text-align: justify;
  }
  .container-string, .container-pirate {
    background-color: #F4D134;
    height: 100vh;
    text-align: justify;
  }

  .heading-chess {
    font-size: 7vh;
    font-weight: 800;
    color: #F4D134;
    letter-spacing: -1px;
    margin-top: 3vh;
    text-align: center;
  }
  
  .heading-pirates {
    font-size: 7vh;
    font-weight: 800;
    color: #2B2E51;
    letter-spacing: 1px;
    margin-top: 7vh;
    text-align: center;

  }

  .heading-yoga {
    font-size: 7vh;
    font-weight: 800;
    color: #F4D134;
    letter-spacing: 0px;
    margin-top: 5vh;
    text-align: center;

  }

  .heading-string {
    font-size: 7vh;
    font-weight: 800;
    color: #2B2E51;
    letter-spacing: 0px;
    margin-top: 10vh;
    text-align: center;

  }

  .heading-lang {
    font-size: 7vh;
    font-weight: 800;
    color: #F4D134;
    letter-spacing: 1px;
    margin-top: 7vh;
    text-align: center;

  }

  .subHeading-chess {
    font-size: 3vh;
    font-weight: 600;
    padding: 4px;
    color: #F4D134;
    letter-spacing: 3px;
    margin-top: 1vh;
    text-align: center;

  }

  .subHeading-pirates {
    font-size: 3vh;
    font-weight: 600;
    padding: 4px;
    color: #2B2E51;
    letter-spacing: 3px;
    text-align: center;

  }

  .subHeading-yoga {
    font-size: 3vh;
    font-weight: 600;
    padding: 4px;
    color: #F4D134;
    letter-spacing: 3px;
    text-align: center;

  }

  .subHeading-string {
    font-size: 3vh;
    font-weight: 600;
    padding: 4px;
    color: #2B2E51;
    letter-spacing: 5px;
    text-align: center;

  }

  .subHeading-lang {
    font-size: 3vh;
    font-weight: 600;
    padding: 4px;
    color: #F4D134;
    letter-spacing: 3px;
    text-align: center;

  }

  .text-chess {
    color: #F4D134;
    margin-top: 1vh;
    letter-spacing: 1px;
    font-size: 3vh;
    opacity: 0;
    text-align: center;

  }

  .text-pirates {
    color: #2B2E51;
    margin-top: 1vh;
    font-size: 3vh;
    letter-spacing: 0px;
    text-align: center;

  }

  .text-yoga {
    color: #F4D134;
    margin-top: 1vh;
    letter-spacing: 1px;
    font-size: 3vh;
    font-weight: 200;
    text-align: center;

  }
  .text-lang {
    color: #F4D134;
    letter-spacing: 1px;
    font-size: 3vh;
    opacity: 0;
    text-align: center;

  }
  #btn-chess {
    position: absolute;
    background-color: #2B2E51;
    border: 3px solid #F4D134;
    left: 18%;
    bottom: 5%;
  }

  #btn-pirates {
    position: absolute;
    background-color: #F4D134;
    border: 3px solid #2B2E51;
    bottom: 0;
    left: 22%;
    margin-bottom: 2vh;
  }

  #btn-yoga {
    position: absolute;
    background-color: #2B2E51;
    border: 3px solid #F4D134;
    bottom: 5%;
    left: 25%;
    margin-bottom: 2vh;
  }


  #btn-string {
    position: absolute;
    background-color: #F4D134;
    border: 3px solid #2B2E51;
    bottom: 5%;
    left: 20%;
    margin-bottom: 1vh;
  }

  #btn-lang {
    position: absolute;
    background-color: #2B2E51;
    border: 3px solid #F4D134;
    left: 22%;
    bottom: 5%;
  }

  .button-text-chess {
    letter-spacing: 2px;
    font-size: large;
    color: #F4D134;
    margin-left: 1vh;
    margin-right: 1vh;
  }

  .button-text-pirates {
    letter-spacing: 2px;
    font-size: large;
    color: #2B2E51;
    margin-left: 1vh;
    margin-right: 1vh;
  }

  .button-text-yoga {
    letter-spacing: 2px;
    font-size: large;
    color: #F4D134;
    margin-left: 1vh;
    margin-right: 1vh;
  }

  .button-text-string {
    letter-spacing: 2px;
    font-size: large;
    color: #2B2E51;
    margin-left: 1vh;
    margin-right: 1vh;
  }

  .button-text-lang {
    letter-spacing: 2px;
    font-size: large;
    color: #F4D134;
    margin-left: 1vh;
    margin-right: 1vh;
  }
}